import './Roadmap.css'
import React, { useEffect } from 'react';

export default function Roadmap() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        
            <div className={"roadmap-title"}>
                <h1>Roadmap</h1>
                <p>Explore our plans for future development of Trophē</p>
            </div>
            <div className={`${"roadmap-sectionHome"} ${"roadmap-sectionFirst"}`}>
                <div>
                    <h2>ICARUS - Q1 2025</h2>
                    <p>
                        <strong>Decentralized Computing Power</strong><br />Connect to virtual machines powered by a network of distributed GPUs. This means you'll have access to serious computing muscle without the hefty price tag of owning high-end hardware.
                    </p>
                    <p>
                        <strong>Smart LLM Training</strong><br /> We're bringing together the best of both worlds - Hugging Face's vast model library and UnslothAI's clever optimization techniques. Train large language models that are both powerful and efficient.
                    </p>
                    <p>
                        <strong>Shrink Models, Not Performance</strong><br /> Using advanced techniques like QLora, we'll help you create smaller, faster models without sacrificing quality. It's like compressing your AI without losing the smarts.
                    </p>
                    <p>
                        <strong>Blockchain-Backed Transparency</strong><br /> Every step of your model's journey is recorded on the blockchain. This isn't just about buzzwords - it's about building trust and allowing you to track your AI's evolution.
                    </p>
                    <p>
                        <strong>Flexible API Access</strong><br /> Whether you're building the next big app or just experimenting, our API makes it easy to tap into your trained models. And yes, it runs on those same distributed GPUs for consistent, scalable performance.
                    </p>
                    <p>
                        <strong>Chat with Your AI</strong><br /> We've built a user-friendly chatbot interface that connects directly to your models. It's perfect for quick tests, demos, or just having a conversation with your AI creation.
                    </p>
                    <p>
                        <strong>Irys</strong><br /> Think of Irys as a super-efficient, programmable datachain. It makes storing and using data on-chain cheaper and more flexible than ever before. Plus, you get some free storage to play with!
                    </p>
                    <p>
                        <strong>Streamlined Training and Storage</strong><br /> We've simplified the entire process - from training your models to storing them securely. Use our robust training modules, then easily save your metadata with Irys and your full models on HuggingFace.
                    </p>
                </div>
                <div>
                    <h2>ALEXANDROS - Q3 2025</h2>
                    <p>
                        <strong>Export Models</strong><br /> Allow users to export their trained models to various formats and platforms, enhancing interoperability and accessibility. This feature ensures that models developed within Trophē can be seamlessly integrated into different environments and applications.
                    </p>
                    <p>
                        <strong>Marketing Strategies</strong><br /> Implement comprehensive marketing strategies to promote Trophē and its capabilities. This includes leveraging platforms like Twitter, Discord, Reddit, and other social media channels to reach a wider audience, engage with the community, and increase overall visibility.
                    </p>
                    <p>
                        <strong>Marketplace IA</strong><br /> Develop a robust marketplace where users can buy and sell AI models. This platform facilitates a thriving ecosystem where developers can monetize their models, and businesses can access a diverse range of AI solutions tailored to their specific needs.
                    </p>
                    <p>
                        <strong>Community Engagement</strong><br /> Foster a strong community by organizing webinars, workshops, and hackathons. Encourage collaboration and knowledge sharing among users to drive innovation and continuous improvement within the Trophē platform.
                    </p>
                </div>
                <div>
                    <h2>ATLAS - Q1 2026</h2>
                    <p>
                        <strong>Trustless AI Development</strong><br /> Utilize cutting-edge technologies like Secure Multi-Party Computation (spML) to enable trustless AI development. This ensures that AI models are developed transparently without relying on centralized authorities, enhancing security and trustworthiness.
                    </p>
                    <p>
                        <strong>API Usage Examples</strong><br /> Develop a comprehensive library of API usage examples to assist developers in integrating Trophē's capabilities into their applications. Provide detailed documentation, code snippets, and tutorials to facilitate smooth adoption and implementation.
                    </p>
                    <p>
                        <strong>Advanced Analytics</strong><br /> Integrate advanced analytics tools to monitor and analyze the performance of deployed models. Offer insights and metrics that help users optimize their models for better efficiency and accuracy.
                    </p>
                    <p>
                        <strong>Continuous Integration and Deployment (CI/CD)</strong><br /> Implement CI/CD pipelines to streamline the development and deployment processes. Enable automatic testing, building, and deployment of models to ensure rapid and reliable updates.
                    </p>
                </div>
                <div>
                    <h2 >LEONIDAS - Q1 2027</h2>
                    <p>
                        <strong>Decentralized Cloud Platform</strong><br /> Develop a decentralized cloud platform that offers scalable and secure infrastructure services. This platform will feature decentralized databases, servers, and storage solutions, ensuring high availability and resilience.
                    </p>
                    <p>
                        <strong>Serverless Computing</strong><br /> Introduce serverless computing capabilities to allow developers to deploy applications without managing server infrastructure. This enhances flexibility and reduces operational overhead.
                    </p>
                    <p>
                        <strong>Integrated Security Features</strong><br /> Incorporate robust security measures, including encryption, authentication, and authorization protocols, to protect data and applications hosted on the cloud platform.
                    </p>
                    <p>
                        <strong>Developer Tools and SDKs</strong><br /> Provide a suite of developer tools and Software Development Kits (SDKs) to facilitate the creation, deployment, and management of applications. Offer APIs, libraries, and integrations that support various programming languages and frameworks.
                    </p>
                    <p>
                        <strong>Scalability and Performance Optimization</strong><br /> Ensure that the cloud platform can scale seamlessly to handle increasing workloads. Optimize performance through efficient resource allocation and load balancing techniques.
                    </p>
                </div>
            </div>
        </>
    )
}