'use client'
import React, { ReactElement, useEffect } from 'react';
import './Home.css'

interface ListItem {
    title: string;
    desc: ReactElement;
    buttonDesc : string;
    buttonLink : string;
}

export default function Home() {
    const listCardDescription: ListItem[] = [
        {
            title: "Seamless AI & Blockchain Integration",
            desc: (
                <p>
                    Experience the perfect harmony of advanced artificial intelligence and blockchain technology. 
                </p>
            ),
            buttonDesc: "Discover More",
            buttonLink : "https://docs.trophe.net/training",
        },
        {
            title: "Accessible & Democratized AI",
            desc: (
                <p>
                    We believe in making AI accessible to everyone. Trophē offers an intuitive interface and a suite of tools that lower the barriers to entry.
                </p>
            ),
            buttonDesc: "Get Started",
            buttonLink : "https://app.trophe.net/",
        },
        {
            title: "Secure, Transparent & Community-Driven",
            desc: (
                <p>
                    Trust in our commitment to security and transparency with blockchain-backed data management.
                </p>
            ),
            buttonDesc: "Discover More",
            buttonLink : "https://docs.trophe.net/database",
        },
    ];

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <>
            <div className={"home"}>
                <div className={"home-mainPhrase"}>
                    <h1>Bridging the gap between blockchain technology and the mainstream with innovative AI tools</h1>
                    <h3>Democratizing access to artificial intelligence through decentralized technologies, ensuring security, transparency, and cost reduction.</h3>
                </div>
                <div className={"home-listCard"}>
                    {listCardDescription.map((value, index) => {
                       return (
                            <div key={index} className={"home-card"}>
                                <h3>{value.title}</h3>
                                {value.desc}
                                <button type="button" onClick={() => window.open(value.buttonLink, '_blank')}>{value.buttonDesc}</button>
                            </div>
                        )
                    })}
                </div>
            </div>

            {/* Blockchain Storage Metadata Using Irys: Transparency */}
            <div className={"home-sectionHome"}>
                <div>
                    <div>
                        <h2>Blockchain Storage Metadata with Irys</h2>
                        <p>
                            Leverage Irys to store and manage your metadata on the blockchain, ensuring unparalleled transparency and immutability. Every transaction and data point is securely recorded, providing full traceability and trust.
                        </p>
                    </div>
                    <div>
                        <h2>Transparency You Can Trust</h2>
                        <p>
                            Our integration with Irys guarantees that all your data operations are transparent and verifiable, fostering trust among users and stakeholders.
                        </p>
                    </div>
                </div>
                <img src={'./Home/irys.png'} alt="Irys Storage" width={600} height={600}/>
            </div>

            {/* Training: Quantization, Cloud Decentralization, Cost Efficiency, Speed, Diverse Models */}
            <div className={"home-sectionHome"}>
                <img src={"./Home/training.png"} alt="Training" width={600} height={600}/>
                <div>
                    <div>
                        <h2>Advanced Training Capabilities</h2>
                        <p>
                            Utilize quantization techniques and cloud decentralization to train models more efficiently. Our platform reduces costs and accelerates training times, enabling you to deploy robust AI solutions swiftly.
                        </p>
                    </div>
                    <div>
                        <h2>Diverse Model Support</h2>  
                        <p>
                            Access a vast array of models through integrations with HuggingFace and Unsloth AI. Whether you're training new models or fine-tuning existing ones, our infrastructure supports your AI development needs.
                        </p>
                    </div>
                </div>
            </div>

            {/* Inference: API, Chatbot, Diverse Models, Export Options */}
            <div className={"home-sectionHome"}>
                <div>
                    <div>
                        <h2>Robust Inference Capabilities</h2>
                        <p>
                            Deploy your models seamlessly with our comprehensive API and chatbot integrations. Enjoy fast and reliable inference performance across a multitude of models.
                        </p>
                    </div>
                    <div>
                        <h2>Flexible Export Options</h2>
                        <p>
                            Maintain full autonomy over your AI models by exporting them at any time. Our platform ensures you’re not locked into any ecosystem, providing the freedom to utilize your models as you see fit.
                        </p>
                    </div>
                </div>
                <img src={'./Home/inference.png'} alt="Inference" width={600} height={600}/>
            </div>

            <div className={"home-sectionHome"}>
                <div className={"home-sectionTitle"}>
                    <h1>OUR VISION</h1>
                    <div>
                        <p>
                            At Trophē, our vision is to seamlessly integrate advanced artificial intelligence with blockchain technology to create a universally accessible platform. We strive to democratize AI by providing an intuitive and flexible interface that caters to users of all technical backgrounds. By harnessing the power of blockchain, we ensure transparency, security, and decentralization, empowering individuals and businesses to leverage AI without barriers.
                        </p>
                        <p>
                            Our commitment is to build a robust cloud infrastructure that serves as the backbone for innovative AI applications. We envision a future where cutting-edge AI tools are readily available to everyone, fostering an inclusive and equitable technological landscape. Through this integration, Trophē aims to drive a new era of innovation, where AI advancements are accessible, reliable, and beneficial for society as a whole.
                        </p>
                    </div>
                    <div>
                        <p>
                            By joining our platform, you become a key player in a technological revolution aimed at making AI beneficial for the entire society. You contribute to an initiative that values simplicity, accessibility, and equity, and you participate in creating a future where innovation is accessible to everyone.
                        </p>
                        <p>
                            Together, we are transforming AI to serve and enrich the entire society, paving the way for a new technological era where everyone has a place and can thrive.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}