import { Link } from 'react-router-dom'
import './Menu.css'
import React from 'react';

export default function Menu(){
    return (
        <>
        <header className="menu-bar">
            <Link to='/'>
                <img src={'./logo/2.png'} alt="Logo" width={70} height={70}/>
            </Link>
            <div className="menu-navigation">
                <Link to='https://docs.trophe.net/' target="_blank">
                    <p>DOCS</p>
                </Link>
                <Link to='/roadmap'>
                    <p>ROADMAP</p>
                </Link>
                <Link to='/contact'>
                    <p>CONTACT</p>
                </Link>
            </div>
            <a href="https://app.trophe.net/" className="menu-buttonStyle" target="_blank" rel="noopener noreferrer">
                <p>APP</p>
                <img src={'./fleche.png'} alt="Arrow" width={18} height={18}/>
            </a>
        </header>
        </>
    )
}